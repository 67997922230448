<template>
  <div class="VideoControl">
    <div v-if="video" class="" style="display:flex;padding:0.5em;font-size:12px;">
      <a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" v-if="video.isPaused" @click="play"><i class="fas fa-play"></i></a>
      <a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" v-else @click="pause"><i class="fas fa-pause"></i></a>
      <span class="text-shadow" style="flex:none">{{ moment(video.currentTime*1000).format('mm:ss')}}</span>
      <input type="range" class="" min="0" :max="video.totalDuration" v-model="video.currentTime" style="flex: auto; margin: 0 5px;" />
      <span class="text-shadow" style="flex:none">{{moment(Number(video.totalDuration)*1000).format('mm:ss')}}</span>
      <!--<a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" @click="stop"><i class="fas fa-stop"></i></a>-->
      <!--<div style="background-color:wheat">{{nowTime}}/{{nowDate}}/{{state}}</div>-->
    </div>
    <div class="subtitleP">
      <div class="subtitle">
        <transition-group name="subtitle">
          <div v-for="s in subtitles" v-bind:key="s.key">
            <div class="subtitle-item">
              {{ s.text }}
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <div class="images">
      <div v-for="i in images" :style="getimgstyle(i)"><img class="img" :src="i.url" /></div>
    </div>
    <!--<template v-for="s in subtitles">
      <div class="subtitleP">
        <div class="subtitle" :class="{show:s.show}">{{s.text}}</div>
      </div>
    </template>-->
  </div>
</template>
<script>
  import uuid from 'uuid'
  import moment from 'moment'

  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        video: null,
        videoPlugin: null,
        nowTime: 0,
        totalDuration: 0,
        updateTimeTimer:null,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {}
    },
    watch: {
    },
    computed: {
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          videoEnable: this.videoEnable,
          videoDisable: this.videoDisable
        },
        events: {
        }
      })
      if (this.getFunc({ target: 'FullPano', name: 'isPanoVideo' })()) {
        this.videoEnable()
      }
    },
    destroyed() {
    },
    methods: {
      moment: moment,
      startUpdateTime() {
        this.updateTimeTimer = setInterval(() => {
          //this.nowTime = this.videoPlugin.time
          this.$forceUpdate()
        }, 100)
      },
      stopUpdateTiem() {
        clearInterval(this.updateTimeTimer)
      },
      videoEnable() {
        this.videoPlugin = this.getFunc({ target: 'FullPano', name: 'getVideoPlayer' })()
        this.video = this.getVideo(this.videoPlugin)
        this.totalDuration = this.video.totaltime
        this.time = this.video.time
        this.startUpdateTime()
      },
      videoDisable() {
        this.video = null
        this.stopUpdateTiem()
      },
      getVideo(videoPlugin) {
        return {
          get currentTime() {
            return videoPlugin.time
          },
          set currentTime(time) {
            videoPlugin.seek(time)
          },
          get totalDuration() {
            return videoPlugin.totaltime
          },
          get isVideoReady() {
            return videoPlugin.isvideoready
          },
          get isPaused() {
            return videoPlugin.ispaused
          },
          get isSeeking() {
            return videoPlugin.isseeking
          },
          get isComplete() {
            return videoPlugin.iscomplete
          },
          get isAutoPaused() {
            return videoPlugin.isautopaused
          },
          get needUserInteraction() {
            return videoPlugin.needuserinteraction
          },
        }
      },
      play() {
        this.videoPlugin.play()
      },
      pause() {
        this.videoPlugin.pause()
      }
    }
  }

</script>
<style scoped>
  .subtitleP {
    position: fixed;
    top: 50vh;
    left: 0;
    right: 0;
    /*bottom: 200px;*/
    pointer-events: none;
  }

    .subtitleP .subtitle {
      /*pointer-events:auto;*/
      /*display: flex;*/
      /*justify-content: center;*/
      /*align-items: center;*/
      text-align: center
    }

  .subtitle-item {
    margin: auto;
    display: inline-block;
    background-color: #0006;
    color: white;
    font-size: 18px;
    padding: 5px;
    max-width: 85vw;
    min-height: 35px;
    /*margin-bottom: 5px;*/
  }

  /*.subtitle-enter-active, .subtitle-leave-active, .subtitle-move {
    transition: all 0.5s;
  }

  .subtitle-enter {
    opacity: 0;
    transform: translateY(30px);
  }

  .subtitle-leave-to*/
  /* .list-leave-active for below version 2.1.8 */ /*{
    opacity: 0;
    transform: translateY(-10px);
  }*/
  .subtitle-enter-active {
    animation: subtitle-in-down 1s;
  }

  .subtitle-leave-active {
    animation: subtitle-out 0.5s;
  }

  @keyframes subtitle-in-down {
    0% {
      opacity: 0;
      /*transform: translateY(-20px);*/
    }

    100% {
      opacity: 1;
      /*transform: translateY(0px);*/
    }
  }

  @keyframes subtitle-out {
    0% {
      opacity: 1;
      margin-top: 0;
    }

    100% {
      opacity: 0;
      margin-top: -35px;
    }
  }
</style>
